// @ts-strict-ignore
import Head from 'next/head';

import { useMemo } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { VideoObject } from 'schema-dts';

import { crush } from '@tedconf/js-crushinator-helpers';
import { Translation } from 'api';
import {
  compose,
  gt,
  host,
  identity,
  ifElse,
  join,
  length,
  map,
  pathOr,
  prop,
  propOr
} from 'f';
import getImageFromSet from 'lib/get-image-from-set';
import { useCanonicalUrl } from 'lib/hooks/useCanonicalUrl';
import { decodeHtmlEntities, formatAsISO8601 } from 'lib/util';

import type TalkHeadProps from './TalkHead.props';
import TalkHeadSocialTags from './TalkHeadSocialTags';

type Language = {
  ianaCode: string;
  languageCode: string;
};

const getLanguageCodes = compose<any, string, any, Language[], Language[]>(
  // TODO: verify the validity of this - ML
  ifElse(compose(gt(0), length), identity, () => [
    { ianaCode: '', languageCode: '' }
  ]),
  propOr([], 'languages'),
  JSON.parse,
  pathOr('{}', ['playerData'])
);

const TalkHead = ({ video, language = 'en', transcript }: TalkHeadProps) => {
  const languages: Language[] = useMemo(() => getLanguageCodes(video), [video]);
  const { slug } = video;
  const canonicalUrl = useCanonicalUrl();

  const transcriptForSEO = useMemo(() => {
    if (!transcript || !transcript.translation) {
      return '';
    }
    const { paragraphs }: Pick<Translation, 'paragraphs'> =
      transcript.translation;
    return paragraphs
      .map(paragraph => {
        return paragraph.cues
          .map(cue => decodeHtmlEntities(cue.text.replace(/\n/g, ' ')))
          .join(' ');
      })
      .join(' ');
  }, [transcript]);

  const embedUrl = useMemo(() => {
    const baseUrl = `https://embed.ted.com/talks/${slug}`;
    return language === 'en' ? baseUrl : `${baseUrl}?language=${language}`;
  }, [slug, language]);

  const talkImage = useMemo(
    () => video && getImageFromSet(video?.primaryImageSet, '16x9'),
    [video]
  );

  const image = crush(talkImage);

  const formattedTopics = `TED, talks, ${compose(
    join(', '),
    map(prop('name'))
  )(video?.topics?.nodes)}`;

  const title = useMemo(
    () =>
      video &&
      `${video.presenterDisplayName ? `${video.presenterDisplayName}:` : ''} ${
        video.title
      }`,
    [video]
  );

  if (!video) {
    return (
      <Head>
        <title>{`${title} | TED Talk`}</title>
        <meta name="title" content={title} />
      </Head>
    );
  }

  return (
    <Head>
      {/* Critical meta tags first */}
      <title>{`${title} | TED Talk`}</title>
      <meta name="title" content={title} />
      <meta name="description" content={video.description} />

      {/* Non-critical meta tags */}
      <TalkHeadSocialTags
        slug={video.slug}
        canonicalUrl={useCanonicalUrl()}
        image={image}
        title={title}
        description={video.description}
        socialTitle={video.socialTitle}
        socialDescription={video.socialDescription}
        duration={video.duration}
        publishedAt={video.publishedAt}
        topics={video.topics}
      />
      <meta name="keywords" content={formattedTopics} />
      {/* TODO: Handle `preview`'s impact on `publishedAt` */}
      <meta name="medium" content="video" />
      <meta name="author" content={video.presenterDisplayName} />
      <link
        rel="alternate"
        type="application/json+oembed"
        title="oEmbed Profile"
        href={`https://www.ted.com/services/v1/oembed.json?url=${encodeURIComponent(
          canonicalUrl
        )}`}
      />
      <link
        rel="alternate"
        type="application/xml+oembed"
        title="oEmbed Profile"
        href={`https://www.ted.com/services/v1/oembed.xml?url=${encodeURIComponent(
          canonicalUrl
        )}`}
      />
      <meta
        name="apple-itunes-app"
        content={`app-id=376183339,app-argument=${canonicalUrl}?utm_source=ted.com&amp;utm_medium=referral&amp;utm_campaign=smart_app_banner`}
      />
      <meta
        property="al:android:url"
        content={`ted://talks/${slug}?source=facebook`}
      />
      <meta
        property="al:ios:url"
        content={`ted://talks/${slug}?source=facebook`}
      />
      <link rel="alternate" hrefLang="x-default" href={canonicalUrl} />
      <script
        {...jsonLdScriptProps<VideoObject>({
          // https://developers.google.com/search/docs/data-types/video#video-object
          '@context': 'https://schema.org',
          '@type': 'VideoObject',
          name: video.title,
          description: video.description,
          thumbnailUrl: `${image}w=640`,
          uploadDate: video.publishedAt,
          duration: formatAsISO8601(video.duration),
          contentUrl: canonicalUrl,
          transcript: transcriptForSEO,
          embedUrl,
          interactionStatistic: {
            '@type': 'InteractionCounter',
            interactionType: { '@type': 'WatchAction' },
            userInteractionCount: video.viewedCount
          }
        })}
      />

      {languages &&
        languages.map(lang => (
          <link
            rel="alternate"
            key={`${lang.languageCode}-${lang.ianaCode}`}
            hrefLang={lang.ianaCode}
            href={`${host}/talks/${slug}?language=${lang.languageCode}`}
          />
        ))}

      <link
        rel="preload"
        href={video.primaryImageSet[0].url}
        as="image"
        type="image/webp"
      />
    </Head>
  );
};

export default TalkHead;
