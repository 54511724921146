// @ts-strict-ignore

import TalkHeadSocialTagsProps from './TalkHeadSocialTags.props';

const TalkHeadSocialTags = ({
  slug,
  canonicalUrl,
  image,
  title,
  description,
  socialTitle,
  socialDescription,
  duration,
  publishedAt,
  topics
}: TalkHeadSocialTagsProps) => {
  return (
    <>
      <meta
        property="og:title"
        data-testid="talk-head-og-title"
        content={socialTitle || title}
      />
      <meta
        property="og:description"
        data-testid="talk-head-og-description"
        content={socialDescription || description}
      />
      <meta property="og:image:width" content="1050" />
      <meta property="og:image:height" content="550" />
      <meta property="og:video:duration" content={String(duration)} />
      <meta property="og:type" content="video.other" />
      <meta
        name="twitter:app:url:ipad"
        content={`ted://talks/${slug}?source=twitter`}
      />
      <meta
        name="twitter:app:url:iphone"
        content={`ted://talks/${slug}?source=twitter`}
      />
      <meta property="twitter:app:url:googleplay" content={canonicalUrl} />
      <meta property="twitter:image" content={`${image}w=1200`} />
      <meta property="og:image" content={`${image}c=1050%2C550&w=1050`} />
      <meta
        property="og:image:secure_url"
        content={`${image}c=1050%2C550&amp;w=1050`}
      />
      <meta
        property="og:video:release_date"
        content={String(String(new Date(publishedAt).getTime()).substr(0, 10))}
      />
      {topics.nodes.map(topic => (
        <meta key={topic.id} property="og:video:tag" content={topic.name} />
      ))}
    </>
  );
};

export default TalkHeadSocialTags;
